:root {
  --landscape-width: 10; // % OR vw for responsive window resizing!
  --portrait-width: 30vw;
  --header-height: 4rem;
  --secs: 0.4s;
  --bg-left: lemonchiffon;
  --bg-main: lemonchiffon;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  overflow: hidden; // Disable scrolling on body
}

#layout {
  display: flex; // Flexbox definition
  overflow: hidden; // Disable scrolling on layout

  // Style in common for sidebars and main area:
  .header {
    position: relative;
    width: 100%;
    height: var(--header-height);
    text-align: center; // Horizontal center
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:first-child{
      padding-left: 20px;
    }
    &:last-child{
      padding-right: 20px;
    }
    .logo{
      position: relative;
      width: 120px;
      height: 100%;
      margin:0 auto;

      display: flex;
      align-items: center;
    }
    .user-profile{
      position: relative;
      ul{
        display: flex;
        list-style: none;
        margin-bottom: 0;
        li{
          margin-right: 2vw;
          img{
            border-radius: 80px;
            position: relative;
            height: 2rem;
          }
        }
      }
    }
  }
  .conteudo_gdi {
    overflow: auto;
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));
    padding: 1rem;
    transition: ease 0.25s;
  }

}

// Main area specific style:
#main { background: #fafafa; flex-grow: 1;
  .header { background-color: #4CB0AE;
    transition: ease 0.25s;
  }
  .conteudo_gdi{
    &.resize{
      transition: ease 0.25s;
      @media(orientation: landscape){
        margin-left: 17vw;
      }
      @media(orientation: portrait){
        margin-left: 30vw;
      }

    }
  }
}

// Style in common for both sidebars:
#left, #right { transition: width var(--secs); width: 0;
  color: white;
  z-index: 0;
  svg{
    margin-right: 5px;
    margin-bottom: 3px;
  }
  @media (orientation: landscape) {
    &.open { width: 30vw }
  } // <-- Landscape ONLY sidebars
  .icon {
    position: fixed; z-index: 10;
    cursor: pointer;
    display: flex;
    height: var(--header-height);
    background-color: #4CB0AE;
    width: 10vw;
    align-items: center;
    justify-content: space-around;
  }
  .sidebar { transition: transform var(--secs); // <-- Portrait AND landscape!

    @media (orientation: portrait) { width: 30vw; }
    @media (orientation: landscape) { width: 20vw; }
    .header { width: calc(20vw); }
    .conteudo_gdi{
      position: fixed;
      margin-top: var(--header-height);
      color : #393939;
      height: 90vh;

      background-color: white;
      padding: 0;
      @media (orientation: portrait) { width: 25vw; }
      @media (orientation: landscape) {width: 15vw;}
    }

  }
}

// Left sidebar specific style:
#left {
  z-index: 5;
  position: absolute;
  .sidebar { background: var(--bg-left);
    &.closed {
      @media(orientation: landscape){
        transform: translateX(-30vw);
      }
      @media(orientation: portrait){
        transform: translateX(-60vw);
      }
     }

  }
  ul{
    width: 100%;
    padding: 0;
  }
  a{
    &:hover{
      color: #000000
    }
  }
  label{
    margin-left: 1vw;
    margin-top: 2vh;
  }
  li{
    padding: 2vh 2vw ;
    transition: ease-in-out 0.25s ;
    width: 100%;

    &:hover{
      background-color:#efe5fd  ;
    }
  }

}


.header{
  a{
    color: #ffffff !important;
    &:hover{
      color: #000000 !important;
    }
  }

}