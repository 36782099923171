@media only screen and (max-width: 4200px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 2vw;
  }
}
@media only screen and (max-width: 1920px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 2vw;
  }
}
@media only screen and (max-width: 1400px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 2vw;
  }
}


.indexGdi {
  position: relative;
  @extend %transition;
  min-height: 450px;
  padding-top: 35%;

  @media #{$laptop-device} {
    min-height: 400px;
  }
  @media #{$lg-layout} {
    min-height: 400px;
  }
  @media #{$md-layout} {
    min-height: 350px;
  }
  @media #{$sm-layout} {
    min-height: 300px;
  }
  .thumbnail-inner {
    transition: transform 0.28s ease;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;

    &::before {
      background-color: #4cb0ae;
      background-image: linear-gradient(#4cb0ae 5%, #000000 100%);
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 0;
      @extend %transition;
    }
  }
  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    background-color: #0a0a0a;
    background-image: url(/static/images/index.jpg);
    transform: scale(1.13) translateZ(0);
    backface-visibility: hidden;
    transition: transform 0.28s ease;
    z-index: 4;
    &.image-1 {
      background-image: url(/static/images/portfolio/portfolio-1.jpg);
    }

    &.image-2 {
      background-image: url(/static/images/portfolio/portfolio-2.jpg);
    }

    &.image-3 {
      background-image: url(/static/images/portfolio/portfolio-3.jpg);
    }

    &.image-4 {
      background-image: url(/static/images/portfolio/portfolio-4.jpg);
    }

    &::after {
      background-color: rgba(45, 45, 45, 0.35);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: ' ';
      z-index: 1;
      display: block;
      border-radius: 5px;
      background-color: rgba(25, 25, 25, 0.37);
      @extend %transition;
    }
  }

  .bg-blr-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-color: #0a0a0a;
    background-image: url(/static/images/portfolio/portfolio-1.jpg);
    @extend %transition;
    transform: translateY(38px);
    opacity: 0;
    z-index: 1;

    &.image-1 {
      background-image: url(/static/images/portfolio/portfolio-1.jpg);
    }
    &.image-2 {
      background-image: url(/static/images/portfolio/portfolio-2.jpg);
    }
    &.image-3 {
      background-image: url(/static/images/portfolio/portfolio-1.jpg);
    }
    &.image-4 {
      background-image: url(/static/images/portfolio/portfolio-1.jpg);
    }
  }

  .conteudo {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    max-width: 100% !important;
    z-index: 10;
    padding: 20px;
    @media #{$lg-layout} {
      left: 0px;
      right: 0px;
    }
    @media #{$md-layout} {
      left: 0px;
      right: 0px;
    }
    @media #{$sm-layout} {
      left: 0px;
      right: 0px;
    }
    .inner {
      p {
        color: #c6c9d8;
        font-size: 14px;
      }
      h4 {
        font-size: 24px;
        line-height: 36px;
        a {
          color: #ffffff;
        }
      }
      .index-button {
        @extend %transition;
        transition: 0.7s;
        display: flex;
        justify-content: end;
        width: 100%;
        @media #{$large-mobile} {
          margin-top: 0px;
        }
        a {
          &.rn-btn {
            color: #ffffff;
            border-color: rgba(255, 255, 255, 0.3);
            margin-right: 5px;
            &:first-child:hover {
              border-color: #4cb0ae;
              background-color: #4cb0ae;
            }
          }
        }
      }
    }
  }
  &:hover {
    .thumbnail-inner {
      transform: scale(1.08) translateZ(0);
      &::before {
        opacity: 0.85;
      }
    }
    .thumbnail {
      transform: scale(1) translateZ(0);
      &::after {
        background-color: rgba(25, 25, 25, 0.24);
      }
    }
    .bg-blr-image {
      opacity: 0;
      z-index: 9;
    }
  }
}
