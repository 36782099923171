.banner-titulo-imagens{
  .cdx-block{
    padding: 0
  }
  .ce-paragraph{
    line-height: 0.8em;
  }
  .codex-editor--narrow .codex-editor__redactor{
    margin-right: 0px;
    min-width: 30px;
    padding-bottom:0px;
  }
}
p.subtitulo-banner-imagem {
  color: white;
  font-size: 4em;
  font-weight: lighter;
}

.jcmc{
  h1{
    line-height: 80px !important;
  }
  p{
    line-height: 50px !important;
    margin-top: -20px;
    -webkit-animation: 1000ms ease-in-out 0s normal none 1 running customOne;
                            animation: 1000ms ease-in-out 0s normal none 1 running customOne;
  }
  @media (max-width:991px) {
    justify-content: center !important;
    h1{
      text-align: center;
      font-size: 4rem !important;
      line-height: 50px !important;
      margin-bottom: 20px!important;
    }
    p{
      font-size: 2rem !important;
      margin-top: 20px !important;
    }
  }
  justify-content: initial;
}