.dropzone {
  .editar-imagens {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 0.5rem;
    .imagem {
      position: relative;
      .layer {
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
        &::before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.3;
        }
        &:hover {
          opacity: 1;
        }
        .icones {
          font-size: 2rem;
          color: white;
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          > * {
            cursor: pointer;
            padding: 2px 2px;
            margin: 0 5px;
            z-index: 2;
            transition: 0.2s all ease-in-out;
            background-color: transparent;
            &:hover {
              background-color: rgba($color: #393939, $alpha: 0.7);
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}
