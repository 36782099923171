.indexGdi{
  .col-lg-4{
    width: 80%;
  }
}

.deletar{
  background: transparent;
  color: #e53935 !important;
  border-color: #e53935 !important;
  &:hover{
    color:#fafafa !important;
    background-color: #e53935 !important;

  }
}
.add-btn, .editar-btn {
  background: transparent;
  color: #3f51b5;
  border-color: #3f51b5 !important;
  transition: all 0.3s ease-in-out;
  padding: 0 23px;
  height: 40px;
  display: inline-block;
  line-height: 34px;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  z-index: 2;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  overflow: visible;
  &:hover{
    color:#fafafa;
    background-color: #3f51b5;
    transform: translateY(-3px);
  }
}

.editar-btn{ margin-right:5px;}

