.quickAccessCard{
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.2s ease-in-out !important;
  backface-visibility: hidden;

  &:hover{
    transform: scale(1.03);
  }
}
.quickLink{
  backface-visibility: hidden;
  transition: all 0.2s ease-in-out !important;
  &:hover{transform: scale(1.03)}
}
