/*********************************************************************************

	Template Name: Trydo React Degital Agency Template
	Note: This is style css.

**********************************************************************************/

/**************************************************************

	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|	|___ mainmenu
	|
	|
	|___Inner Styles
	|	|___ breadcrump
	|	|___ section-title
	|	|___ button
	|	|___ pagination
	|	|___ socialicon
	|	|___ footer
	|
	|___Elements Styles
	|	|___ portfolio
	|	|___ service
	|	|___ slider
	|	|___ counterup
	|	|___ testimonial
	|	|___ brand
	|	|___ team
	|	|___ callto-action
	|	|___ tab
	|	|___ accordion
	|	|___ list
	|	|___ contact
	|
	|___BLog Styles
	|	|___ blog
	|	|___ blog-details
	|
	|___Template Styles
	|	|___ about
	|	|___ portfolio-details
	|	|___ service-details
	|	|___ preview
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/




/**************************************
    Default Styles
***************************************/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap');
@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';

@import 'gdi/indexServicos';
@import 'gdi/home_gdi.scss';
@import 'gdi/editor.scss';
/**************************************
    Header Styles
***************************************/

@import 'header/header';
@import 'header/mainmenu';
@import 'header/sidemenu';


/**************************************
    Inner Styles
***************************************/

@import 'common/breadcrump';
@import 'common/section-title';
@import 'common/button';
@import 'common/pagination';
@import 'common/socialicon';
@import 'common/footer';



/**************************************
    Elements Styles
***************************************/

@import 'elements/portfolio';
@import 'elements/service';
@import 'elements/slider';
@import 'elements/counterup';
@import 'elements/testimonial';
@import 'elements/brand';
@import 'elements/team';
@import 'elements/callto-action';
@import 'elements/tab';
@import 'elements/accordion';
@import 'elements/list';
@import 'elements/contact';
@import 'elements/progress';
@import 'elements/column';
@import 'elements/404';
@import 'elements/pricing';
@import 'elements/indexGdi';
@import 'elements/galeria';
@import 'elements/dropzone';

/**************************************
    Blog Styles
***************************************/

@import 'blog/blog';
@import 'blog/blog-details';



/**************************************
    Template Styles
***************************************/

@import 'template/about';
@import 'template/portfolio-details';
@import 'template/service-details';
@import 'template/dark-version';
@import 'template/preview';

.conteudo_gdi{
	a{color: #616161;
		transition: ease 1s both !important;
		&:hover{
			color: #393939

		}
	}

}
.galeria-dialogo{
	.MuiDialog-paperWidthSm {
		max-width: 62vw;
		@media (max-width: 700px){
			max-width: 100vw;
		}
	}
}
 .MuiFab-root {
	 z-index: 999;
	 position: fixed;
	 right: 25px;
	 bottom: 25px;
 }
