.galeria {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1.5rem;

  // position: relative;
  .imagem {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    .detalhes{
      transition: all 0.2s ease-in-out;

      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(to top, rgba($color: #000, $alpha: 0.5), rgba(255,0,0,0));
      opacity: 0;
      .conteudo{
        display: flex;
        height: 100%;
        align-items: flex-end;
        justify-content: center;
        h3{
          color: white;
          font-size: medium;
        }
      }
    }
      &:hover {
        transform: scale(1.05);
        .detalhes{
          opacity: 1;
        }
      }
  }
}

.galeria-dialogo{
  .imagem-dialogo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    .detalhes{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      background: linear-gradient(to top, rgba($color: #000, $alpha: 0.3), rgba(255,0,0,0));
      transition: all ease-in-out .2s;
      &:hover{
        opacity: 1;
      }
      .conteudo{

        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 15px;
        margin-left: 15px;
        > * {
          color: white;
        }
      }
    }
  }
}